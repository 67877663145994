import { DeleteOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, notification, PageHeader, Popconfirm, Space } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";

export const Chats: FC = () => {
  const { profileId } = useParams();
  const [chats, setChats] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const getChats = async () => {
    const response = await request.post("/administrator/chat/list", {
      profileId,
    });

    setChats(response.data);
    setLoading(false);
  };

  const deleteChats = async (): Promise<void> => {
    try {
      await request.post("/administrator/chat/delete-all-chats", { profileId });
      notification.success({ message: "Ура! Чаты удалены!" });
    } catch (err) {
      console.error(err);
    }

    await getChats();
  };

  useEffect(() => {
    getChats();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Чаты"
        extra={
          <Popconfirm
            title="Точно удалить?"
            onConfirm={deleteChats}
            cancelText="Нет, я передумал"
            okText="Ну конечно!"
            placement="leftTop"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              Удалить все чаты
            </Button>
          </Popconfirm>
        }
      />

      {chats.length ? (
        <Space style={{ width: "100%" }} direction="vertical">
          {chats.map((chat) => (
            <a key={chat.id} href={`/chats/messages/${chat.id}`} target="_blank" rel="noreferrer">
              <Space
                style={{
                  border: "1px solid #ccc",
                  padding: 8,
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <Space style={{ minWidth: 200 }}>
                  <Avatar
                    src={
                      chat.first_profile?.avatar
                        ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${chat.first_profile.avatar.image}`
                        : "https://amorus.ru/assets/images/stub_male.png"
                    }
                  />
                  <span>{chat.first_profile.name}</span>
                </Space>
                <Space style={{ minWidth: 200 }}>
                  <Avatar
                    src={
                      chat.second_profile?.avatar
                        ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${chat.second_profile.avatar.image}`
                        : "https://amorus.ru/assets/images/stub_male.png"
                    }
                  />
                  <span>{chat.second_profile.name}</span>
                </Space>
                <span>Сообщений: {chat.message?.length || 0}</span>
              </Space>
            </a>
          ))}
        </Space>
      ) : (
        <Alert message="У этого пользователя нет чатов :(" type="info" />
      )}
    </div>
  );
};
